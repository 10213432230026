<template>
  <a-card :bordered="false" class="card-area">

    <common-table
      ref="table"
      path="product_classify"
      :columns="columns">

      <template slot="search">
        <a-button ghost type="primary" @click="add">新增</a-button>
      </template>

      <template slot="operation" slot-scope="{record}">
        <action-edit @click="edit(record)"></action-edit>
        <action-delete @click="remove(record)"></action-delete>
      </template>

    </common-table>

    <product-classify-edit ref="productClassifyEdit" @success="getList"></product-classify-edit>

  </a-card>

</template>
<script>

import ProductClassifyEdit from './ProductClassifyEdit'

export default {
  components: {
    ProductClassifyEdit,
  },
  data () {
    return {

    }
  },

  computed: {
    columns () {
      return [
        {
          title: '分类名称',
          dataIndex: 'name',
        },
        {
          title: '排序',
          dataIndex: 'sort'
        },
        {
          title: '操作',
          dataIndex: 'operation',
          scopedSlots: {customRender: 'operation'},
        }
      ]
    }
  },
  methods: {

    add(){
      this.$refs.productClassifyEdit.show()
    },
    edit (record) {
      this.$refs.productClassifyEdit.show(record)
    },
    remove (record) {
      var that = this
      this.$confirm({
        title: '是否删除该分类?',
        content: '该操作不可撤销',
        centered: true,
        onOk () {
          that.$delete('product_classify/' + record.id).then(() => {
            that.$message.success('删除成功')
            that.getList()
          })
        }
      })
    },

    getList () {
      this.$refs.table.getData()
    },

  }
}
</script>
